import API from "../API";
import TopKvestovService from "./TopKvestovService";

export default class TopKvestovParser {

    constructor() {
        this.page = 1;
        this.cityId = 0;
        this.id = 0;
        this.from = null;
        this.to = null;
    }


    async getQuests() {

        let res = await API.get('https://topkvestov.ru/api-v2/quests?include=location,category,city', {
            params: {
                city: this.cityId || 64,
            },
            header: {
                'TopkvestovToken': TopKvestovService.API_KEY
            }
        });

        if(res.status === 200){
            return res.data.data;
        }
    }

    async getSchedule(){
        let res = await API.get('https://topkvestov.ru/api-v2/get-quest-schedule/' + this.id, {
            params: {
                mode: 'groupByDate'
            }
        });
        return res.data;
    }

    async getReviews(id){
        let res = await API.get('https://topkvestov.ru/api-v2/quests/' + id + '?include=comments', {
            header: {
                'TopkvestovToken': TopKvestovService.API_KEY
            }
        });

        if(res.status === 200){
            return res.data.data.comments.data;
        }
    }

    async getLimitReviews(id, count) {
        let res = await API.get('https://topkvestov.ru/api-v2/quests/'+ id +'/comments', {
            params: {
                page: 1,
                per_page: count
            },
            header: {
                'TopkvestovToken': TopKvestovService.API_KEY
            }
        });

        if(res.status === 200){
            return res.data.data;
        }
    }

}