import API from "./API";
import axios from "axios";
import qs from "qs";
import TopKvestovService from './topkvestov/TopKvestovService';
import connect from '@vkontakte/vkui-connect';

export default class VKApi {

    static request_id = 0;

    sendMessage(id, token, message) {
        // console.log(id, token);
        // connect.send("VKWebAppCallAPIMethod", {
        //     method: "messages.send",
        //     request_id: "32test",
        //     params: {
        //         user_id: id,
        //         access_token: token,
        //         message,
        //         random_id: VKApi.request_id
        //     }
        // })
        // connect.send("VKWebAppCallAPIMethod", {
        //     method: "messages.send",
        //     request_id: VKApi.request_id,
        //     params: {
        //         group_id: 117828424,
        //         "user_id": id,
        //         "v": "5.95",
        //         "access_token": token
        //     }
        // });

        axios.post(`https://topkvestov.ru/api-v2/vk-service/send-message`, qs.stringify({
            //user_id: id,
            access_token: token,
            message,
            random_id: VKApi.request_id
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'TopkvestovToken': TopKvestovService.API_KEY,
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        VKApi.request_id++;
    }

}