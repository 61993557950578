import React from 'react';
import {Panel, HeaderButton, PanelHeader, PanelHeaderContent, Div, Link} from "@vkontakte/vkui";
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Icon24Gallery from '@vkontakte/icons/dist/24/gallery';
import Icon24Recent from '@vkontakte/icons/dist/24/recent';
import Icon24Copy from '@vkontakte/icons/dist/24/copy';
import {Cell, Group, InfoRow, List} from "@vkontakte/vkui";
import {FormLayout, Input} from "@vkontakte/vkui";
import {Textarea} from "@vkontakte/vkui";
import {Button} from "@vkontakte/vkui";
import isMobilePhone from 'validator/lib/isMobilePhone';
import isAlpha from 'validator/lib/isAlpha';
import isEmail from 'validator/lib/isEmail';
import {Radio} from "@vkontakte/vkui";
import {Units} from "../controllers/Units";
import moment from "moment";
import connect from '@vkontakte/vkui-connect';
import VKApi from "../controllers/VKApi";
import {Tabs} from "@vkontakte/vkui";
import {TabsItem} from "@vkontakte/vkui";
import {Checkbox} from "@vkontakte/vkui";

export default class Reservation extends React.Component{

    constructor(props) {
        super(props);

        this.acceptMessage = () => {};
        this.rejectMessage = () => {};

        this.state = {
            slot: this.props.slot,
            notifyVK: false,
            accepted: {
                phone: false,
                email: false,
                message: false
            },
            form: {
                name: {
                    value: '',
                    valid: null
                },
                surname: {
                    value: '',
                    valid: null
                },
                phone: {
                    value: '',
                    valid: null
                },
                comment: {
                    value: '',
                    valid: true
                },
                email: {
                    value: '',
                    valid: null
                }
            }
        };

        if(!!this.props.fetchedUser){
            this.state.form.email.value = `${this.props.fetchedUser.email || ''}`;
            this.state.form.name.value = this.props.fetchedUser.first_name;
            this.state.form.surname.value = this.props.fetchedUser.last_name || null;
            this.state.form.name.valid = true;
            this.state.form.surname.valid = true;

            if(!!this.props.fetchedUser.phone){
                this.state.form.phone.value = `${this.props.fetchedUser.phone || ''}`;
                this.state.form.phone.valid = true;
            }

            if(!!this.props.fetchedUser.email){
                this.state.form.email.value = `${this.props.fetchedUser.email || ''}`;
                this.state.form.email.valid = true;
            }

            this.setState({
                ...this.state
            });

        }


        connect.subscribe((e) => {
            switch (e.detail.type) {

                case 'VKWebAppGetPhoneNumberResult':
                    if(!!e.detail.data.phone_number){
                        this.state.form.phone.value = `${e.detail.data.phone_number || ''}`;
                        this.state.form.phone.valid = true;
                    }
                    this.setState({
                        ...this.state
                    });
                    break;
                case 'VKWebAppGetEmailResult':
                    if(!!e.detail.data.email){
                        this.state.form.email.value = `${e.detail.data.email || ''}`;
                        this.state.form.email.valid = true;
                    }
                    this.setState({
                        ...this.state
                    });
                    break;
                case "VKWebAppAccessTokenReceived":
                    if (!!e.detail.data.access_token) {
                        this.setState({ access_token: e.detail.data.access_token });
                        this.acceptMessage();
                    }
                    break;
                case 'VKWebAppAllowMessagesFromGroupResult':
                    if(!!e.detail.data.result){
                        this.state.accepted.message = true;
                        this.acceptMessage();
                    }
                    break;
            }
        });

    }

    get regionName(){
        if(!!this.props.region){
            return this.props.region.name || null
        }else{
            return null
        }
    }

    get questNameHeader(){
        if(!!this.props.quest){
            let name = this.props.quest.name;
            if(name.length > 20) name = `${name.slice(0, 20)}...`;
            return name;
        }else{
            return null;
        }
    }

    get questName(){
        if(!!this.props.quest){
            let name = this.props.quest.name;
            if(name.length > 25) name = `${name.slice(0, 25)}...`;
            return name;
        }else{
            return null;
        }
    }

    get isValid(){
        let valid = true;
        let form = this.state.form;
        for(let key in form){
            valid = form[key].valid;
            if(!valid) return false;
        }
        return valid;
    }

    get bookPrice(){
        return this.props.slot.tickets.filter( el => {
            return el.isActive;
        })[0].price;
    }

    get bookAmount(){
        return this.props.slot.tickets.filter( el => {
            return el.isActive;
        })[0].amount;
    }

    typedInput(name, value){
        value = value.trim();
        this.state.form[name].value = value;

        switch (name) {
            case 'name' :
                this.state.form[name].valid = isAlpha(value, "ru-RU");
                if(!!value && value.length > 30){
                    this.state.form[name].value = value.slice(0, 30);
                }
                break;
            case 'surname' :
                this.state.form[name].valid = isAlpha(value, "ru-RU");
                if(!!value && value.length > 30){
                    this.state.form[name].value = value.slice(0, 30);
                }
                break;
            case 'phone' :
                this.state.form[name].valid = isMobilePhone(value);
                if(!!value && value.length > 13){
                    this.state.form[name].value = value.slice(0, 13);
                }
                if(!!value && value.length < 8){
                    this.state.form[name].valid = false;
                }
                break;
            case 'comment' :
                if(!!value && value.length > 200){
                    this.state.form[name].value = value.slice(0, 200);
                }
                break;
            case 'email' :
                this.state.form[name].valid = isEmail(value);
                if(!!value && value.length > 254){
                    this.state.form[name].value = value.slice(0, 254);
                }

                if( this.state.form[name].valid ){
                    if( /[а-яА-ЯЁё]/.test(value) ){
                        this.state.form[name].valid = false;
                    }
                }

                break;
        }
        this.setState({
            ...this.state
        });
    }

    selectGroup(ticket){
        this.state.slot.tickets.forEach( el => {
            el.isActive = false;
        });
        ticket.isActive = true;
        this.setState({...this.state});
    }

    submit(){

        this.props.service.reservator.date = this.props.selectedDay.date;
        this.props.service.reservator.time = this.props.slot.time;
        this.props.service.reservator.price = this.bookPrice;
        this.props.service.reservator.players = this.bookAmount;
        this.props.service.reservator.first_name = this.state.form.name.value;
        this.props.service.reservator.family_name = this.state.form.surname.value;
        this.props.service.reservator.phone = this.state.form.phone.value;
        this.props.service.reservator.email = this.state.form.email.value;
        this.props.service.reservator.comment = this.state.form.comment.value;
        this.props.service.reservator.id = this.props.quest.id;

        this.props.toggleLoading(true);

        this.props.service.reservator.submit().then( res => {
            this.props.toggleLoading(false);
            let errorText = 'При попытке бронирования возникла ошибка.';
            if(!!res.success && res.success){
                //if(!!this.props.fetchedUser){
                    let vksendText = ``;
                    if(this.state.notifyVK){
                        vksendText = " и в личные сообщения";
                        new VKApi().sendMessage(this.props.fetchedUser.id, this.props.token, `
Здравствуйте!
Рады сообщить вам, что квест «${ this.props.quest.name }» успешно забронирован!

Дата и время: ${ moment(this.props.selectedDay.date).format('DD MMMM YYYY (dddd)') } в ${ this.state.slot.time }
Цена за игру: ${ Units.currency(this.props.service.reservator.price) }
Адрес: ${ this.props.quest.address }
Телефон: ${ this.props.quest.phone }

Задать дополнительные вопросы или отменить бронирование вы можете по телефону, указанному выше.
        `);
                    }

                    this.props.successBrone({
                        title: `Квест «${ this.props.quest.name }» успешно забронирован!`,
                        text: `Вы успешно забронировали квест. Более подробная информация отправлена Вам на почту${ vksendText }.`,
                        onClose: () => {
                            this.props.setPanel('quest');
                        }
                    });

                    // this.props.setPanel('quest');
                // }else{
                //     this.props.successBrone({
                //         title: `Квест «${ this.props.quest.name }» успешно забронирован!`,
                //         text: `
                //         Адрес: ${ this.props.quest.address } <br>
                //         Телефон: ${ this.props.region.phone } <br>
                //
                //         Задать дополнительные вопросы или отменить бронирование вы можете по телефону, указанному выше.
                //     `
                //     });
                // }
            }else{
                if(!!res.message) errorText = res.message;
                this.props.successBrone({
                    title: 'Ошибка',
                    text: errorText
                });
            }


        }, err => {
            this.props.toggleLoading(false);
            this.props.successBrone({
                title: 'Ошибка',
                text: 'При попытке бронирования возникла ошибка.'
            });
        });

    }

    get nameStatus(){
        if(this.state.form.name.valid) return 'valid';
        if(this.state.form.name.valid === false) return 'error';
        return null;
    }

    get surnameStatus(){
        if(this.state.form.surname.valid) return 'valid';
        if(this.state.form.surname.valid === false) return 'error';
        return null;
    }

    get phoneStatus(){
        if(this.state.form.phone.valid) return 'valid';
        if(this.state.form.phone.valid === false) return 'error';
        return null;
    }

    get emailStatus(){
        if(this.state.form.email.valid) return 'valid';
        if(this.state.form.email.valid === false) return 'error';
        return null;
    }

    changeVkSend(){
        if(!this.state.accepted.message){
            this.acceptMessage = () => {
                this.setState({
                    notifyVK: !this.state.notifyVK
                });
            };
            connect.send("VKWebAppGetAuthToken", {app_id: 7003144, scope: ""});
            connect.send("VKWebAppAllowMessagesFromGroup", {"group_id": 117828424, "key": "dBuBKe1kFcdemzB"});
        }else{
            this.setState({
                notifyVK: !this.state.notifyVK
            });
        }
    }

    render() {
        return (
            <Panel id={ this.props.id }>
                <PanelHeader
                    left={ <HeaderButton data-to="schedule" onClick={ this.props.go }><Icon24Back/></HeaderButton> }
                >
                    <PanelHeaderContent status={ !!this.regionName ? `Город: ${this.regionName}` : null }>
                        { this.questNameHeader }
                    </PanelHeaderContent>
                </PanelHeader>
                <Group title="Кол-во человек">
                    {this.state.slot.tickets.map( (el, index) => (
                        <Radio onChange={ () => {this.selectGroup(el)} } key={index} name="amount" checked={ el.isActive }>{ `${el.name} - ${ Units.currency(el.price) }` }</Radio>
                    ))}
                </Group>
                <Group title="Информация о брони">
                    <List>
                        <Cell before={<Icon24Gallery/>}>
                            <InfoRow title="Квест">{ this.questName }</InfoRow>
                        </Cell>
                        <Cell before={<Icon24Copy/>}>
                            <InfoRow title="Дата">{ moment(this.props.selectedDay.date).format('DD MMMM YYYY (dddd)') }</InfoRow>
                        </Cell>
                        <Cell before={<Icon24Recent/>}>
                            <InfoRow title="Время">{ this.state.slot.time }</InfoRow>
                        </Cell>
                    </List>
                    <FormLayout>
                        <Input
                            status={ this.nameStatus }
                            top="Имя *"
                            value={ this.state.form.name.value }
                            onChange={ (e) => {
                                let {value} = e.currentTarget;
                                this.typedInput('name', value);
                            }}
                        />
                        <Input
                            status={ this.surnameStatus }
                            top="Фамилия *"
                            value={ this.state.form.surname.value }
                            onChange={ (e) => {
                                let {value} = e.currentTarget;
                                this.typedInput('surname', value);
                            }}
                        />
                        <Input
                            status={ this.phoneStatus }
                            value={ this.state.form.phone.value }
                            top="Телефон *"
                            onClick={ () => {
                                if(!this.state.accepted.phone){
                                    connect.send("VKWebAppGetPhoneNumber", {});
                                    this.state.accepted.phone = true;
                                }
                            } }
                            type="tel"
                            placeholder="7xxxxxxx"
                            onChange={ (e) => {
                                let {value} = e.currentTarget;
                                this.typedInput('phone', value);
                            }}
                        />
                        <Input
                            status={ this.emailStatus }
                            value={ this.state.form.email.value }
                            top="E-mail *"
                            onClick={ () => {
                                if(!this.state.accepted.email){
                                    connect.send("VKWebAppGetEmail", {});
                                    this.state.accepted.email = true;
                                }
                            } }
                            type="text"
                            placeholder="test@example.com"
                            onChange={ (e) => {
                                let {value} = e.currentTarget;
                                this.typedInput('email', value);
                            }}
                        />
                        <Textarea top="Комментарий"
                            value={ this.state.form.comment.value }
                            onChange={ (e) => {
                                let {value} = e.currentTarget;
                                this.typedInput('comment', value);
                            }}
                        />
                        { !!this.props.fetchedUser && <Group>
                            <Checkbox
                                checked={ !!this.state.access_token }
                                onChange={ () => { this.changeVkSend(); } }
                            >Получить информацию о бронировании в личные сообщения.</Checkbox>
                        </Group> }
                        <Button size="xl" disabled={ !this.isValid } onClick={ () => {this.submit()} }>Забронировать</Button>
                        <Div>
                            Нажимая кнопку «Забронировать», Вы принимаете
                            <Link href={ `https://topkvestov.ru/agreement?utm_source=vk&utm_medium=sevice&utm_campaign=${this.props.quest.id}` } target="_blank"> условия соглашения о персональных данных</Link>.
                        </Div>
                    </FormLayout>
                </Group>
            </Panel>
        )
    }


}