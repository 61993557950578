import React from 'react';
import connect from '@vkontakte/vkui-connect';
import { View } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import Persik from './panels/Persik';
import AllQuests from "./panels/AllQuests";
import {Root} from "@vkontakte/vkui";
import CitiesSearch from "./panels/CitiesSearch";
import QuestPage from "./panels/QuestPage";
import Schedule from "./panels/Schedule";
import ScheduleDays from "./panels/ScheduleDays";
import Reservation from "./panels/Reservation";
import TopKvestovService from "./controllers/topkvestov/TopKvestovService";
import TopKvestovParser from "./controllers/topkvestov/TopKvestovParser";
import TopKvestovResrvator from "./controllers/topkvestov/TopKvestovResrvator";
import {ScreenSpinner} from "@vkontakte/vkui";
import API from "./controllers/API";
import Reviews from "./panels/Reviews";
import MapPanel from "./panels/Map";
import moment from 'moment';
import 'moment/locale/ru';
import {Alert} from "@vkontakte/vkui";
import Genres from "./panels/Genres";
import parse from 'html-react-parser';
import './panels/main.css';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.catsList = {};

		this.state = {
			activePanel: 'list',
			activeView: 'mainView',
			fetchedUser: null,
			isWebVersion: false,
			access_token: null,
			region: {
				id: 64,
				name: "Санкт-Петербург",
				phone: "+7 (812) 603-49-38",
				quests_count: 122
			},
			quest: null,
			day: null,
			slot: null,
			service: null,
			popout: null,
			cities: [],
			categories: [],
			firstLoading: true,
			history: ['main']
		};

		this.state.service = new TopKvestovService(
			new TopKvestovParser(),
			new TopKvestovResrvator()
		);

		this.toggleLoading(true);
		this.state.service.loadQuests().then( () => {
			this.toggleLoading(false);
			this.state.firstLoading = false;
			this.setState({...this.state});
			this.parseCategories(this.state.service.bagList);
		}, () => {
			this.toggleLoading(false);
			this.showAlert({
				title: 'Ошибка',
				text: 'Во время получения списка квестов возникла ошибка.'
			});
		});

		this.loadingCities();

		moment.locale('ru');


	}

	componentDidMount() {

		console.log(window.location);

		connect.subscribe((e) => {
			switch (e.detail.type) {
				case 'VKWebAppGetUserInfoResult':
					this.setState({ fetchedUser: e.detail.data });
					break;
				case "VKWebAppAccessTokenReceived":
					if (!!e.detail.data.access_token) {
						this.setState({ access_token: e.detail.data.access_token });
					}
					break;
				case 'VKWebAppGeodataResult':
					let {lat, long, available } = e.detail.data;
					if(!!available){
						this.parseGeolocation(lat, long);
					}else{
						this.setView('mainView');
						this.parseGeolocationError();
					}
					break;
				case 'VKWebAppInitResult':
					let isWebVersion = false;
					if (window.innerWidth > 606) {
						isWebVersion = true;
					}
					this.setState({
						isWebVersion: isWebVersion
					});
					break;
			}
		});

		connect.send("VKWebAppInit", {});
		connect.send('VKWebAppGetUserInfo', {});

	}

	go = (e) => {
		this.setState({
			...this.state,
			activePanel: e.currentTarget.dataset.to
		});
	};

	setPanel = (name) => {
		this.setState({
			...this.state,
			activePanel: name
		});
	};

	goView = (e) => {
		this.setState({
			...this.state,
			activeView: e.currentTarget.dataset.to
		});
	};

	setView = (view) => {
		this.setState({
			...this.state,
			activeView: view
		});
	};

	selectCity = (city) => {
		if(city.id === 0){
			this.detectGeo();
			return;
		}
		this.state.service.parser.cityId = city.id;
		this.toggleLoading(true);
		this.state.firstLoading = true;
		this.state.service.loadQuests().then( () => {
			this.toggleLoading(false);
			this.state.firstLoading = false;
			this.setState({...this.state});
			this.parseCategories(this.state.service.bagList);
		}, () => {
			this.toggleLoading(false);
			this.showAlert({
				title: 'Ошибка',
				text: 'Во время получения списка квестов возникла ошибка.'
			});
		});
		this.state.region = city;
		this.state.service.list = [];
		this.state.categories = [];
		this.setState({
			...this.state,
			region: city,
			categories: []
		});
		this.setView('mainView');
	};

	selectQuest = (quest) => {
		this.setState({
			...this.state,
			quest,
			activePanel: 'quest'
		});
	};

	selectDay = (day) => {
		this.setState({
			...this.state,
			day,
			activePanel: 'schedule'
		});
	};

	resetSchedule = () => {
		this.setState({
			...this.state,
			day: null
		})
	};

	selectSlot = (slot) => {
		this.state.activePanel = 'reservation';
		this.setState({
			...this.state,
			slot
		});
	};

	toggleLoading = (flag) => {
		let popout = this.state.popout;
		if(popout === null){
			this.state.popout = <ScreenSpinner/>
		}else{
			this.state.popout = null;
		}

		if(flag) this.state.popout = <ScreenSpinner/>;
		if(!flag) this.state.popout = null;

		this.setState({...this.state});
	};

	gotoSchedulePage = (e) => {
		let quest = this.state.quest;
		this.toggleLoading(true);
		this.state.service.loadSchedule(quest.id).then( () => {
			this.toggleLoading(false);
			let $quest = this.state.service._findQuestById(quest.id);
			let defaultDate = $quest.schedule[0];
			this.state.day = defaultDate;
			this.setState({...this.state});
		}, () => {
			this.toggleLoading(false);
			this.showAlert({
				title: 'Ошибка',
				text: 'Во время получения списка квестов возникла ошибка.'
			});
		});
		this.go(e);
	};

	loadingCities = () => {
		API.get('https://topkvestov.ru/api-v2/cities').then( res => {
			let cities = res.data.data;
			this.state.cities = cities;
			this.setState({...this.state});
			if(!!this.state.fetchedUser && !!this.state.fetchedUser.city && !!this.state.fetchedUser.city.title){
				let city = cities.find(el => {
					if(el.name.toLowerCase() === this.state.fetchedUser.city.title.toLowerCase()){
						return el;
					}
				});
				if(!!city){
					this.selectCity(city);
				}
			}
		}, () => {
			this.toggleLoading(false);
			this.showAlert({
				title: 'Ошибка',
				text: 'Во время получения списка квестов возникла ошибка.'
			});
		});
	};

	parseCategories = (list) => {

		let catsIds = {
			0: []
		};
		let catsNames = {};

		if(list instanceof Array){
			for(let quest of list){
				if(!!quest.category){
					let cat = quest.category.data;
					const parsedQuest = this.state.service._parseQuest(quest);

					if(!catsNames.hasOwnProperty(cat.id)){
						catsNames[cat.id] = cat;
					}
					if(catsIds.hasOwnProperty(cat.id)){
						catsIds[cat.id].push(parsedQuest);
					}else{
						catsIds[cat.id] = [parsedQuest];
					}

					catsIds[0].push(parsedQuest);
				}
			}
		}

		const allCats = {
			name: "Все",
			slug: "all",
			id: 0,
			cover: null,
			created_at: null
		};

		this.catsList = catsIds;
		let resultCats = Object.keys(catsNames).map( key => {
			return {
				...catsNames[key],
				count: catsIds[key].length,
				isActive: false
			}
		});

		resultCats = resultCats.sort((a, b) => {
			return b.count - a.count;
		});

		resultCats.unshift(
			{
				...allCats,
				count: resultCats.length === 0 ? 0 : resultCats
					.map(el => {
						return el.count || 0;
					})
					.reduce((el, acc) => {
						return acc + el;
					}),
				isActive: true
			}
		);

		this.setState({
			...this.state,
			categories: resultCats
		});
	};

	selectCategory = (cat) => {
		let isSame = false;
		let selectedCat = cat;

		let cats = this.state.categories;
		const activeTab = cats.find( el => {
			return !!el.isActive;
		});

		if(activeTab.id === cat.id) isSame = true;

		cats.forEach( el => {
			el.isActive = false;
		});

		if(!isSame){
			cat.isActive = true;
		}else{
			const allCat = cats.find(el => {return el.id === 0});
			if(!!allCat){
				allCat.isActive = true;
				selectedCat = allCat;
			}
		}

		let quests = this.catsList[selectedCat.id];
		this.state.service.list = quests;

		this.setState({...this.state});
	};

	showAlert = (meta) => {
		this.setState({
			...this.state,
			popout: <Alert
				onClose={ () => {
					if (!!meta.onClose) {
						meta.onClose();
					}
					this.setState({...this.state, popout: null})
				} }
				actionsLayout="vertical"
				actions={ [{
					title: 'Закрыть',
					autoclose: true,
					style: 'cancel'
				}] }
			>
				<h2>{ meta.title }</h2>
				<p>{ parse(meta.text) }</p>
			</Alert>
		});
	};

	closeAlert = () => {
		this.setState({
			...this.state,
			popout: null
		})
	};

	detectGeo = () => {
		connect.send("VKWebAppGetGeodata", {});
	};

	parseGeolocation = (lat, lon) => {
		this.toggleLoading(true);
		this.setView('mainView');
		API.get(`https://topkvestov.ru/api-v2/suggest-geo`, {
			params: {
				lat,
				lng: lon
			}
		}).then( res => {
			const { data } = res.data;
			if(!!data && data.length > 0){
				this.selectCity(data[0]);
			} else {
				this.toggleLoading(false);
				this.parseGeolocationError();
			}
		}, () => {
			this.toggleLoading(false);
			this.showAlert({
				title: `Ошибка`,
				text: `Во время определения местоположения возникла ошибка.`
			});
		});
	};

	parseGeolocationError = () => {
		this.showAlert({
			title: 'Ошибка',
			text: 'Не удалось определить Ваше местоположение.'
		});
	};

	goBack = () => {
		const history = [...this.state.history];
		history.pop();
		const activePanel = history[history.length - 1];
		if (activePanel === 'main') {
			connect.send('VKWebAppDisableSwipeBack');
		}
		this.setState({ history, activePanel });
	};

	goForward = (activePanel) => {
		const history = [...this.state.history];
		history.push(activePanel);
		if (this.state.activePanel === 'main') {
			connect.send('VKWebAppEnableSwipeBack');
		}
		this.setState({ history, activePanel });
	};

	render() {
		return (
			<Root activeView={this.state.activeView}>
				<View onSwipeBack={this.goBack} history={this.state.history}  popout={this.state.popout} activePanel={this.state.activePanel} id="mainView">
					<AllQuests isFirstLoad={ this.state.firstLoading } selectCategory={ this.selectCategory } categories={ this.state.categories } service={ this.state.service } id="list" goView={this.goView} region={ this.state.region } selectQuest={ this.selectQuest } reset={ this.resetSchedule } />
					<QuestPage service={ this.state.service } id="quest" alert={ this.showAlert } toggleLoading={ this.toggleLoading } isWeb={this.state.isWebVersion} go={this.go} gotoSchedule={ this.gotoSchedulePage } region={ this.state.region } quest={ this.state.quest } />
					<Schedule service={ this.state.service } id="schedule" go={this.go} region={ this.state.region } quest={ this.state.quest } selectSlot={ this.selectSlot } selectedDay={ this.state.day }/>
					<ScheduleDays service={ this.state.service } id="scheduleDays" go={this.go} region={ this.state.region } quest={ this.state.quest } select={ this.selectDay } />
					<Reservation fetchedUser={this.state.fetchedUser} token={ this.state.access_token } toggleLoading={ this.toggleLoading } setPanel={ this.setPanel } service={ this.state.service } successBrone={ this.showAlert } id="reservation" go={ this.go } region={ this.state.region } quest={ this.state.quest } selectedDay={ this.state.day } slot={ this.state.slot } />
					<Reviews id="reviews" service={ this.state.service } alert={ this.showAlert } closeAlert={ this.closeAlert } go={ this.go } region={ this.state.region } quest={ this.state.quest } toggleLoading={ this.toggleLoading } />
					<MapPanel id="map" go={ this.go } region={ this.state.region } quest={ this.state.quest } />
					<Genres id="genres" region={ this.state.region } />
				</View>
				<View id="searchView" activePanel="cities">
					<CitiesSearch id="cities" onExit={() => {this.setView('mainView');}} cities={ this.state.cities } selectCity={ this.selectCity }/>
				</View>
				<View id="errors" activePanel="internet">
					<CitiesSearch id="cities" onExit={() => {this.setView('mainView');}} cities={ this.state.cities } selectCity={ this.selectCity }/>
				</View>
			</Root>
		);
	}
}

export default App;
