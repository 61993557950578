import { YMaps, Map, Placemark } from 'react-yandex-maps';
import React from 'react';
import {Panel, HeaderButton, PanelHeader, PanelHeaderContent} from "@vkontakte/vkui";
import Icon24Back from '@vkontakte/icons/dist/24/back';
import {Cell, Group} from "@vkontakte/vkui";

export default class MapPanel extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            mapOptions: {
                minZoom: 4
            },
            mapStyle: {
                height: 0
            },
            map: {
                zoom: 12,
                center: this.props.quest.coords
            }
        };
    }

    componentDidMount() {
        const el = document.querySelector(".map__address");
        const panelEl = document.querySelector(".map_panel");
        const headerEl = panelEl.childNodes[0];
        const height = el.getBoundingClientRect().height;
        const headerHeight = window.getComputedStyle(headerEl, null).getPropertyValue('padding-top').replace(/[^\d;]/g, '');
        const panelMargin = window.getComputedStyle(el, null).getPropertyValue('margin-top').replace(/[^\d;]/g, '');

        this.setState({
            mapStyle: {
                height: `calc(100vh - ${height + Number(headerHeight) + (panelMargin * 2)}px)`
            }
        });
    }

    get regionName(){
        if(!!this.props.region){
            return this.props.region.name || null
        }else{
            return null
        }
    }

    get questName(){
        if(!!this.props.quest){
            let name = this.props.quest.name;
            if(name.length > 20) name = `${name.slice(0, 20)}...`;
            return name;
        }else{
            return null
        }
    }

    render() {
        return (
            <Panel id={ this.props.id } className="map_panel">
                <PanelHeader
                    left={ <HeaderButton data-to="quest" onClick={ this.props.go || null }><Icon24Back/></HeaderButton> }
                    className="map__header"
                >
                    <PanelHeaderContent status={ !!this.regionName ? `Город: ${this.regionName}` : null }>
                        { this.questName }
                    </PanelHeaderContent>
                </PanelHeader>
                <Group title="Адрес" className="map__address">
                    <Cell multiline>{ this.props.quest.address }</Cell>
                </Group>
                <div className="map__overlay" style={this.state.mapStyle}>
                    <YMaps>
                        <Map defaultState={ this.state.map } options={ this.state.mapOptions } width="100%" height="100vh">
                            <Placemark geometry={ this.props.quest.coords }/>
                        </Map>
                    </YMaps>
                </div>
            </Panel>
        );
    }

}