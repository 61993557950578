import React from 'react';
import {Panel, HeaderButton, PanelHeader, PanelHeaderContent} from "@vkontakte/vkui";
import Icon24Back from '@vkontakte/icons/dist/24/back';
import {Group, Select, Div} from "@vkontakte/vkui";
import {Cell} from "@vkontakte/vkui";
import {List} from "@vkontakte/vkui";
import {Units} from "../controllers/Units";
import moment from 'moment';
import parse from "html-react-parser";

export default class Schedule extends React.Component {

    constructor(props){
        super(props);
    }

    get regionName(){
        if(!!this.props.region){
            return this.props.region.name || null
        }else{
            return null
        }
    }

    get questName(){
        if(!!this.props.quest){
            let name = this.props.quest.name;
            if(name.length > 20) name = `${name.slice(0, 20)}...`;
            return name;
        }else{
            return null;
        }
    }

    calculateMinPrice(tickets){
        let prices = tickets.map( el => {return el.price});
        return Math.min(...prices);
    }

    render() {
        return(
            <Panel id={ this.props.id }>
                <PanelHeader
                    left={ <HeaderButton data-to="quest" onClick={ this.props.go }><Icon24Back/></HeaderButton> }
                >
                    <PanelHeaderContent status={ !!this.regionName ? `Город: ${this.regionName}` : null }>
                        { this.questName }
                    </PanelHeaderContent>
                </PanelHeader>
                <Group title="Выберите день">
                    <List>
                        <Cell data-to="scheduleDays" onClick={ this.props.go }>
                            <Select>
                                {!!this.props.selectedDay &&
                                    <option>{ moment(this.props.selectedDay.date).format('DD MMMM YYYY (dddd)') }</option>
                                }
                            </Select>
                        </Cell>
                    </List>
                </Group>
                {!!this.props.selectedDay && <Group title="Выберите время">
                    <List>
                        {this.props.selectedDay.slots.length > 0 ?
                        this.props.selectedDay.slots.map( (el, index) => (
                            <Cell onClick={ () => {this.props.selectSlot(el)} } data-to="reservation" key={index} expandable indicator={ Units.currency(this.calculateMinPrice(el.tickets)) }>
                                { el.time }
                            </Cell>
                        ))
                        :
                            <Div>
                                <div className="warning__alert">Нет доступного времени.</div>
                            </Div>
                        }
                    </List>
                </Group>}
                {!!this.props.quest.scheduleComment && <Group>
                    <Cell multiline>{ parse(this.props.quest.scheduleComment) }</Cell>
                </Group>}
            </Panel>
        )
    }

}