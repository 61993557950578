import React from 'react';
import {Panel, PanelHeaderContent} from "@vkontakte/vkui";

export default class Genres extends React.Component{

    get regionName(){
        if(!!this.props.region){
            return this.props.region.name || null
        }else{
            return null
        }
    }

    render() {
        return (
            <Panel id={ this.props.id }>
                <PanelHeaderContent>
                    { this.questName }
                </PanelHeaderContent>
            </Panel>
        )
    }

}