import React from 'react';
import './Review.css'
import StarRatings from 'react-star-ratings';
import Icon24User from '@vkontakte/icons/dist/24/user';

export default class Review extends React.Component{

    render() {
        return (
            <div className="review">
                <div className="review__cover">
                    { !!this.props.cover ?
                        <img className="review__cover" alt="cover" src={ this.props.cover }/>
                        : <div className="review__cover-avatar"><Icon24User/></div>
                    }

                </div>

                { !!this.props.rating &&  <div className="review__rating">
                    <StarRatings
                        rating={ this.props.rating }
                        numberOfStars={ 10 }
                        starDimension="15px"
                        starSpacing="2px"
                    />
                </div> }
                <div className="review__user">{ this.props.userName }</div>
                <div className="review__text">{ this.props.text }</div>
            </div>
        )
    }

}