import React from "react";
import { Panel, PanelHeader, PanelHeaderContent, HeaderButton, Search, Div, Tabs, Cell } from "@vkontakte/vkui";
import Icon24Place from '@vkontakte/icons/dist/24/place';
import QuestCard from '../components/QuestCard'
import {Button} from "@vkontakte/vkui";
import Categories from "../components/Categories";
import {Group} from "@vkontakte/vkui";
import {TabsItem} from "@vkontakte/vkui";
import {Select} from "@vkontakte/vkui";
import './allquests.css'
import VKApi from "../controllers/VKApi";

class AllQuests extends React.Component {

    static searchValue = "";

    constructor(props){
        super(props);
        this.state = {
            id: props.id,
            list: [],
            selectedQuest: null,
            search: AllQuests.searchValue,
            categories: []
        };
        this.props.reset();

    }

    get regionName(){
        return !!this.props.region ? this.props.region.name : null;
    }

    get quests(){
        // let search = this.state.search.toLowerCase();
        let search = AllQuests.searchValue.toLowerCase().trim();
        return this.props.service.list.filter( ({name}) => name.toLowerCase().indexOf(search) > -1);
    }

    selectQuest(quest){
        // quest.id = 13648; //id тестового квеста
        this.props.selectQuest(quest);
    }

    loadMore(){
        this.props.service.showMore().then( () => {
            this.setState({...this.state});
        })
    }

    onChange = (search) => {
        AllQuests.searchValue = search;
        this.setState({
            ...this.state,
            search
        });
    };

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const { target } = e;
            target.blur();
        }
    };

    render() {
        return (
            <Panel id={this.state.id}>
                <PanelHeader left={<HeaderButton onClick={this.props.goView} data-to="searchView"><Icon24Place/></HeaderButton>}>
                    <PanelHeaderContent
                        onClick={ this.props.goView }
                        data-to="searchView"
                        status={ !!this.regionName ? `Город: ${this.regionName}` : null}
                    >
                        Все квесты
                    </PanelHeaderContent>
                </PanelHeader>
                <Search theme="default" onKeyDown={this._handleKeyDown} value={ this.state.search } onChange={ this.onChange }/>
                <Div>
                    <Categories list={ this.props.categories } select={ this.props.selectCategory }/>
                </Div>
                <div className="quest__list">
                    {this.quests.length > 0 ? this.quests.map( (el, index) => {
                            return <div className="quest__item" key={index}>
                                <QuestCard src={el.cover} select={() => {
                                    this.selectQuest(el);
                                }} name={el.name} price={ el.price } age={ el.age } maxPeople={ el.maxPeople } minPeople={ el.minPeople } diff={ el.diff } duration={ el.duration } address={el.address} />
                            </div>
                        })
                        :
                        <Div className="allquests__none">
                            { !this.props.isFirstLoad &&  <div className="allquests__empty">К сожалению, по вашему запросу мы ничего не нашли. Попробуйте изменить параметры поиска.</div> }
                        </Div>
                    }
                </div>

                { false && this.props.service.list.length > 0 && <Div>
                    <Button onClick={ () => {this.loadMore()} } size="xl">Показать ещё</Button>
                </Div> }
            </Panel>
        )
    }

}

export default AllQuests;