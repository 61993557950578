import React from 'react';
import Flickity from 'react-flickity-component';
import './Categories.css'

export default class Categories extends React.Component {

    constructor(props){
        super(props);
        this.await = false;
        this.state = {
            flkty: null,
            options: {
                prevNextButtons: false,
                pageDots: false,
                cellAlign: 'left',
                freeScroll: false,
                touchVerticalScroll: false,
                contain: true
            },
            list: []
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
            let activeIndex = 0;
            let i = 0;
            for(let item of this.props.list){
                if(item.isActive) activeIndex = i;
                i++;
            }
            if(!!this.flkty){
                this.flkty.select(activeIndex);
                this.flkty.on('dragStart', () => (
                    document.ontouchmove = e => e.preventDefault()
                ));
                this.flkty.on('dragEnd', () => (document.ontouchmove = () => true));
            }

        }, 1000)

    };

    select(el){
        if(this.await) return;
        this.await = true;
        this.props.select(el);
        setTimeout(() => {
            this.await = false
        }, 500);
    }

    render(){
        return (
            <div className="categories">
                {this.props.list.length > 0 && <Flickity options={ this.state.options } flickityRef={c => this.flkty = c}>
                    {this.props.list.length > 0 && this.props.list.map( (el, index) =>
                        <button onClick={ (e) => { this.select(el); } } key={index} className={`categories__item ${ el.isActive ? 'categories__item_active' : '' }`}>
                            <div className="categories__item-name">{ el.name }</div>
                            <span className="categories__item-count">{ el.count }</span>
                        </button>
                    )}
                </Flickity>}
            </div>
        )
    }

}