import React from 'react';
import {Panel} from "@vkontakte/vkui";
import {PanelHeader, PanelHeaderContent, Div} from "@vkontakte/vkui";
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Icon24Recent from '@vkontakte/icons/dist/24/recent';
import Icon24Users from '@vkontakte/icons/dist/24/users';
import Icon24Privacy from '@vkontakte/icons/dist/24/privacy';
import Icon24Phone from '@vkontakte/icons/dist/24/phone';
import Icon24Place from '@vkontakte/icons/dist/24/place';
import Icon24Flash from '@vkontakte/icons/dist/24/flash';
import {HeaderButton, Link, FixedLayout} from "@vkontakte/vkui";
import './QuestPage.css';
import {Group} from "@vkontakte/vkui";
import {Cell, List} from "@vkontakte/vkui";
import {InfoRow} from "@vkontakte/vkui";
import {Button} from "@vkontakte/vkui";
import parse from 'html-react-parser';
import Flickity from 'react-flickity-component';
import Icon24Linked from '@vkontakte/icons/dist/24/linked';
import {Gallery} from "@vkontakte/vkui";
import axios from 'axios';
import Review from "../components/Review";

export default class QuestPage extends React.Component{

    constructor(props){
        super(props);
        this.flkty = null;
        this.state = {
            description: this.props.quest.description,
            sliderOptions: {
                prevNextButtons: false,
                contain: true,
                pageDots: true
            },
            reviews: []
        };
    }

    get amountGallery(){
        const { gallery } = this.props.quest;
        return !!gallery && gallery.length <= 1;
    }

    get regionName(){
        if(!!this.props.region){
            return this.props.region.name || null
        }else{
            return null
        }
    }

    get questName(){
        if(!!this.props.quest){
            let name = this.props.quest.name;
            if(name.length > 18) name = `${name.slice(0, 18)}...`;
            return name;
        }else{
            return null;
        }
    }

    get duration(){
        return `${this.props.quest.duration || 0} минут`
    }

    get amountPeople(){
        return `${this.props.quest.minPeople} - ${this.props.quest.maxPeople} чел.`
    }

    get questAddress(){
        if(!!this.props.quest.address){
            let name = this.props.quest.address;
            if(name.length > 25) name = `${name.slice(0, 25)}...`;
            return name;
        }else{
            return null;
        }
    }

    get questLink(){
        if(!!this.props.quest.url){
            let name = this.props.quest.url;
            if(name.length > 25) name = `${name.slice(0, 25)}...`;
            return name;
        }else{
            return null;
        }
    }

    get reviews(){
        return this.state.reviews.map( el => {
            return{
                userName: el.user.data.name,
                text: el.content,
                rating: el.value,
                cover: el.user.data.avatar || null
            }
        });
    }

    componentDidMount = () => {
        setTimeout(() => {
            if(!!this.flkty){
                this.flkty.on('dragStart', () => (
                    document.ontouchmove = e => e.preventDefault()
                ));
                this.flkty.on('dragEnd', () => (document.ontouchmove = () => true));
            }

        }, 1000);

        this.loadingReviews();

    };

    loadingReviews = () => {
        this.props.toggleLoading(true);
        this.props.service.parser.getLimitReviews(this.props.quest.id, 3).then(res => {
            this.setState({reviews: res});
            this.props.toggleLoading(false);
        }, err => {
            this.props.toggleLoading(false);
            this.props.alert({
                title: 'Ошибка',
                text: 'Во время получения отзывов возникла ошибка.'
            });
        });
    };

    get price() {
        return new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB', minimumFractionDigits: 0, maximumFractionDigits: 20}).format(this.props.quest.price)
    }

    render() {
        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    left={ <HeaderButton data-to="list" onClick={ this.props.go || null }><Icon24Back/></HeaderButton> }
                >
                    <PanelHeaderContent status={ !!this.regionName ? `Город: ${this.regionName}` : null }>
                        { this.questName }
                    </PanelHeaderContent>
                </PanelHeader>
                <div className="quest-page">
                    <Div>
                        <div className={ `quest-page__slider ${this.amountGallery ? 'quest-page__slider_clear' : ''}` } style={{ height: 200 }}>
                            <Flickity options={ this.state.sliderOptions } flickityRef={c => this.flkty = c}>
                                { this.props.quest.gallery.map( (el, index) =>
                                    <div key={index} className="quest-page__slide">
                                        <img alt="cover" src={ el } />
                                    </div>
                                ) }
                            </Flickity>
                        </div>
                    </Div>
                    <Div>
                        <div className="quest-page__title">{ this.props.quest.name }</div>
                    </Div>
                    <div className="quest-page__meta">
                        <Group title="Характеристики">
                            <List>
                                <Cell
                                    before={<Icon24Recent/>}
                                >
                                    <InfoRow title="Время игры">{ this.duration }</InfoRow>
                                </Cell>
                                <Cell
                                    before={<Icon24Users/>}
                                >
                                    <InfoRow title="Количество человек">{ this.amountPeople }</InfoRow>
                                </Cell>
                                { !!this.props.quest.diff && <Cell
                                    before={<Icon24Flash/>}
                                >
                                    <InfoRow title="Сложность">{ `${ this.props.quest.diff } / 10` }</InfoRow>
                                </Cell>}
                                { !!this.props.quest.age && <Cell
                                    before={<Icon24Privacy/>}
                                >
                                    <InfoRow title="Ограничение по возрасту">{this.props.quest.age}+</InfoRow>
                                </Cell> }
                                { !!this.props.quest.url && <Cell
                                    before={<Icon24Linked/>}
                                >
                                    <InfoRow title="Ссылка на сайт">
                                        <Link href={`${this.props.quest.url}?utm_source=vk&utm_medium=sevice&utm_campaign=${this.props.quest.id}`} target="_blank">{this.questLink}</Link>
                                    </InfoRow>
                                </Cell> }
                            </List>
                        </Group>
                        { !!this.props.region && <Group title="Контакты">
                            <List>
                                <Cell
                                    expandable={ !this.props.isWeb }
                                    before={<Icon24Phone/>}
                                >
                                    <InfoRow title="Телефон бронирования">{
                                        this.props.isWeb
                                            ?
                                            <div>{this.props.region.phone}</div>
                                            :
                                            <Link href={`tel: ${this.props.region.phone}`}>{this.props.region.phone}</Link>
                                    }</InfoRow>
                                </Cell>
                                <Cell
                                    expandable
                                    data-to="map"
                                    onClick={ this.props.go }
                                    before={ <Icon24Place/> }>
                                    <InfoRow title="Адрес">{ this.questAddress }</InfoRow>
                                </Cell>
                            </List>
                        </Group> }
                    </div>

                    <Group title="Отзывы">
                        { this.reviews.length > 0 && this.reviews.map((el, index) => (
                            <Div key={index}>
                                <Review userName={ el.userName } cover={el.cover} text={ el.text } rating={ el.rating }/>
                            </Div>
                        )) }
                        { this.reviews.length === 0 && (
                            <Div>
                                <div className="reviews__empty">К сожалению, отзывов пока нет.</div>
                            </Div>
                        ) }
                    </Group>
                    { this.reviews.length > 0 && <Div>
                        <Button size="xl" level="secondary" onClick={ this.props.go } data-to="reviews">Все отзывы</Button>
                    </Div> }
                    <Group title="Описание">
                        <Div>{ parse(this.state.description) }</Div>
                    </Group>
                    <FixedLayout vertical="bottom">
                        <Div className="quest-page__brone">
                            <Button data-to="schedule" onClick={ this.props.gotoSchedule || null } level="commerce" size="xl">Бронировать от { this.price }</Button>
                        </Div>
                    </FixedLayout>
                </div>
            </Panel>
        )
    }

}
