import React from 'react';
import {Panel, HeaderButton, PanelHeader, PanelHeaderContent} from "@vkontakte/vkui";
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Review from "../components/Review";
import {Div} from "@vkontakte/vkui";
import {Group} from "@vkontakte/vkui";
import './reviews.css'

export default class Reviews extends React.Component{

    constructor(props){
        super(props);
        this.props.toggleLoading();
        let id = this.props.quest.id;
        this.loadingReviews(id);
        this.state = {
            reviews: [],
            loading: true
        };
    }

    get regionName(){
        if(!!this.props.region){
            return this.props.region.name || null
        }else{
            return null
        }
    }

    get questName(){
        if(!!this.props.quest){
            let name = this.props.quest.name;
            if(name.length > 20) name = `${name.slice(0, 20)}...`;
            return name;
        }else{
            return null
        }
    }

    get list(){
        return this.state.reviews.map( el => {
            return{
                userName: el.user.data.name,
                text: el.content,
                rating: el.value,
                cover: el.user.data.avatar || null
            }
        });
    }

    loadingReviews(id){
        this.props.toggleLoading(true);
        this.props.service.parser.getReviews(id).then( list => {
            this.props.toggleLoading(false);
            if(!!list){
                this.setState({
                    ...this.state,
                    reviews: list,
                    loading: false
                });
            }
        }, err => {
            this.props.toggleLoading(false);
            this.props.alert({
                title: 'Ошибка',
                text: 'Во время получения отзывов возникла ошибка.'
            });
            this.setState({
                loading: false
            });
        });
    }

    render() {
        return (
            <Panel id={ this.props.id }>
                <PanelHeader
                    left={ <HeaderButton data-to="quest" onClick={ this.props.go || null }><Icon24Back/></HeaderButton> }
                >
                    <PanelHeaderContent status={ !!this.regionName ? `Город: ${this.regionName}` : null }>
                        { this.questName }
                    </PanelHeaderContent>
                </PanelHeader>
                {this.list.length > 0 ? this.list.map((el, index) =>
                    (<Group key={index}>
                        <Div>
                            <Review userName={ el.userName } cover={el.cover} text={ el.text } rating={ el.rating } />
                        </Div>
                    </Group>))
                    :
                    <Div>
                        { !this.state.loading && <div className="reviews__empty">К сожалению, отзывов пока нет.</div> }
                    </Div>
                }
            </Panel>
        )
    }

}