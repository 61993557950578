import React from 'react';
import {Panel, PanelHeader, PanelHeaderContent, HeaderButton, Search, List, Cell, Group} from "@vkontakte/vkui";
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import {Div} from "@vkontakte/vkui";

class CitiesSearch extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            id: props.id,
            search: '',
            selectedCity: null
        };

        this.cities_ = [];

        this.cities = this.props.cities;
    }

    get cities(){
        let search = this.state.search.toLowerCase().trim();
        let list = this.cities_.filter( ({name}) => name.toLowerCase().indexOf(search) > -1);
        list.unshift({
            id: 0,
            name: 'Ваше местоположение',
            quests_count: null
        });
        return list
    }

    get isEmptyList(){
        return this.cities.length === 1;
    }

    set cities(val){
        this.cities_ = val;
    }

    cancel = () => {
        this.props.onExit();
    };

    onChange = (search) => {
        this.setState({
            ...this.state,
            search
        })
    };

    select = (city) => {
        this.props.selectCity(city);
    };

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const { target } = e;
            target.blur();
        }
    };

    render() {
        return (
            <Panel id={this.state.id}>
                <PanelHeader
                    left={<HeaderButton onClick={ this.cancel }><Icon24Cancel/></HeaderButton>}
                >
                    <PanelHeaderContent status="Список городов">
                        ТОП Квестов
                    </PanelHeaderContent>
                </PanelHeader>
                <Search theme="default" onKeyDown={this._handleKeyDown} value={ this.state.search } onChange={ this.onChange }/>
                <List>
                    {this.cities.length > 0 && this.cities.map( el => <Cell indicator={ el.quests_count } expandable onClick={ () => {this.select(el)} } key={el.id}>{el.name}</Cell>)}
                </List>
                { this.isEmptyList && <Div>
                    <div className="allquests__empty">К сожалению, по вашему запросу мы ничего не нашли. Попробуйте изменить параметры поиска.</div>
                </Div> }
            </Panel>
        )
    }

}

export default CitiesSearch;