import React from 'react';
import {Panel, HeaderButton, PanelHeader, PanelHeaderContent} from "@vkontakte/vkui";
import Icon24Back from '@vkontakte/icons/dist/24/back';
import {Cell, Group, List} from "@vkontakte/vkui";
import moment from "moment";
import {Div} from "@vkontakte/vkui";

export default class ScheduleDays extends React.Component{

    get regionName(){
        if(!!this.props.region){
            return this.props.region.name || null
        }else{
            return null
        }
    }

    get questName(){
        if(!!this.props.quest){
            let name = this.props.quest.name;
            if(name.length > 20) name = `${name.slice(0, 20)}...`;
            return name;
        }else{
            return null
        }
    }

    get days(){
        return this.props.quest.schedule
            .filter( el => {
                return el.slots.length > 0
            });
    }

    render() {
        return (
            <Panel id={ this.props.id }>
                <PanelHeader
                    left={ <HeaderButton data-to="schedule" onClick={ this.props.go }><Icon24Back/></HeaderButton> }
                >
                    <PanelHeaderContent status={ !!this.regionName ? `Город: ${this.regionName}` : null }>
                        { this.questName }
                    </PanelHeaderContent>
                </PanelHeader>
                <Group>
                    <List>
                        { this.days.length > 0 ?
                            this.days.map( el => (
                                <Cell key={ el.date } onClick={ () => {this.props.select(el)} }>{ moment(el.date).format('DD MMMM YYYY (dddd)') }</Cell>
                            ))
                            :
                            <Div>
                                <div className="warning__alert">Нет доступных дат.</div>
                            </Div>
                        }
                    </List>
                </Group>
            </Panel>
        )
    }


}