import React from 'react';
import './QuestCard.css';
import Icon24Users from '@vkontakte/icons/dist/24/users';
import Icon24Recent from '@vkontakte/icons/dist/24/recent';
import Icon24Privacy from '@vkontakte/icons/dist/24/privacy';
import {Button} from "@vkontakte/vkui";

class QuestCard extends React.Component{

    constructor(props){
        super(props);
        this.state = {

        };
    }

    get amountPeople(){
        return `${this.props.minPeople}-${this.props.maxPeople} чел.`
    }

    get price() {
        return new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB', minimumFractionDigits: 0, maximumFractionDigits: 20}).format(this.props.price)
    }

    render() {
        return (
            <div className="quest-card" onClick={ this.props.select }>
                <img src={this.props.src} alt="" className="quest-card__cover"/>
                <div className="quest-card__meta">
                    <div className="quest-card__list">
                        <div className="quest-card__item">
                            <div className="quest-card__item-icon"><Icon24Users/></div>
                            <div className="quest-card__item-text">{ this.amountPeople }</div>
                        </div>
                        <div className="quest-card__item">
                            <div className="quest-card__item-icon"><Icon24Recent/></div>
                            <div className="quest-card__item-text">{ this.props.duration } мин.</div>
                        </div>
                        <div className="quest-card__item">
                            <div className="quest-card__item-icon"><Icon24Privacy/></div>
                            <div className="quest-card__item-text">{ this.props.age }+</div>
                        </div>
                    </div>
                    <div className="quest-card__meta-title">{ this.props.name }</div>
                    <div className="quest-card__meta-address">{ this.props.address }</div>
                    <div className="quest-card__price">
                        <div className="quest-card__meta-price">От <span className="quest-card__meta-priceval">{ this.price }</span></div>
                        <div className="quest-card__brone">
                            <Button size="l" onClick={ this.props.select }>Забронировать</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default QuestCard;