export default class TopKvestovService{

    static API_KEY = 'fuJ3SV8ptkitbT7jSIfV';
    static SCHEDULE_OFFSET = 1; // month

    constructor(
        parser,
        reservator
    ){
        this.parser = parser;
        this.reservator = reservator;
        this.list = [];
        this.bagList = [];
    }

    async loadQuests() {

        let quests = await this.parser.getQuests();
        let parsedQuests = [];

        if(!!quests && quests instanceof Array){
            parsedQuests = quests.map( el => {return this._parseQuest(el)})
        }
        this.list = parsedQuests;
        this.bagList = quests;

        return parsedQuests;
    }

    async showMore() {
        this.parser.page++;
        let quests = await this.parser.getQuests();
        let parsedQuests = [];


        if(!!quests && quests instanceof Array){
            parsedQuests = quests.map( el => {return this._parseQuest(el)})
        }
        this.list = [...this.list, ...parsedQuests];
        this.bagList = [...this.bagList, ...quests];
        return parsedQuests;
    }

    async loadSchedule(id){
        this.parser.id = id;
        let schedule = await this.parser.getSchedule();
        let parsedSchedule = [];

        if(!!schedule){
            parsedSchedule = Object.keys(schedule)
                .map( key => {
                    return this._parseSchedule({[key]: schedule[key]});
                })
                .filter(el => {
                    return !!el.slots && el.slots.length > 0
                });
        }

        this._findQuestById(id).schedule = parsedSchedule;
        return parsedSchedule;

    }

    _findQuestById(id){
        return this.list.find( el => {return el.id === id});
    }

    _parseQuest(data){
        let quest = {};
        quest.id = data.id || null;
        quest.bag = data;
        quest.name = data.name || null;
        quest.address = !!data.location ? data.location.data.address : null;
        quest.minPeople = data.min_people || null;
        quest.maxPeople = data.max_people || null;
        quest.diff = data.diff || null;
        quest.scheduleComment = data.price_desc || null;
        quest.url = data.url || null;
        quest.price = data.min_price || null;

        let phones = data.phone || '';
        quest.phone = phones.split(',')[0];
        quest.age = data.age || null;
        quest.description = data.content || null;
        quest.schedule = [];
        let coords = [];

        if(!!data.location){
            let lat = data.location.data.lat;
            let lon = data.location.data.lng;
            coords = [lat, lon];
        }

        quest.coords = coords;

        let images = data.images;
        let cover = null;
        if(!!images && images instanceof Array && images.length > 0){
            cover = images[0]['460_320'];
        }else{
            cover = data.cover || null;
        }
        quest.cover = cover;

        quest.duration = data.time || null;

        quest.gallery = !!data.images ? data.images.map( el => { return el['460_320'] }) : null;

        return quest;
    }

    /**
     *
     * @param data {Object}
     * @key date {string}
     * @value slots {Array}
     * @private
     */
    _parseSchedule(data){
        let date = Object.keys(data)[0];
        return {
            date,
            slots: this._parseScheduleSlots(data[date])
        };
    }

    /**
     *
     * @param slots {Array}
     * @private
     */
    _parseScheduleSlots(slots){
        if(!!slots){
            return slots
                .filter( el => {return el.is_free})
                .map( el => {
                    return {
                        time: el.time || null,
                        tickets: this._parseScheduleTickets(el.price_groups)
                    }
                });
        }else{
            return [];
        }
    }

    /**
     *
     * @param tickets
     * @return {Array}
     * @private
     */
    _parseScheduleTickets(tickets){
        let ticks = tickets.map( el => {
            return {
                amount: el.persons,
                name: `${el.persons} чел.`,
                price: el.price || null,
                isActive: false
            }
        });
        if(ticks.length > 0){
            ticks[0].isActive = true
        }
        return ticks;
    }



}