import TopKvestovService from "./TopKvestovService";
import axios from 'axios';
import qs from 'qs';

export default class TopKvestovResrvator{

    constructor(){
        this.first_name = null;
        this.family_name = null;
        this.phone = null;
        this.email = null;
        this.comment = null;
        this.date = null;
        this.time = null;
        this.price = null;
        this.players = null;
        this.id = null;
    }

    async submit() {

        let res = await axios.post(`https://topkvestov.ru/api-v2/add-quest-booking/${this.id}`, qs.stringify({
            family_name: this.family_name,
            first_name: this.first_name,
            phone: this.phone,
            email: this.email,
            comment: this.comment,
            date: this.date,
            time: this.time,
            price: this.price,
            players: this.players
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'TopkvestovToken': TopKvestovService.API_KEY,
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        
        return res.data;
    }

}